import React, { useEffect, useState } from 'react';
import Header from '../Componets/Header';
import SimplePlaidLink from '../Componets/SimplePlaidLink';
import { FaArrowsRotate, FaDownload } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../service/apiService';
import { toast } from 'react-toastify';
import Adminsidebar from '../Componets/Admin/Adminsidebar';
import AdminMobileSidebar from '../Componets/Admin/AdminMobileSidebar';

const RequestReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const token = localStorage.getItem('token');

  const getAllRequestsReports = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await getApi('plaid/get_all_requests_reports');
      setLoading(false);

      if (response?.data) {
        console.log(response.data)
        setRequestData(response.data)
      }

    } catch (error) {
      console.error("Error occurred while fetching asset report:", error);
      toast('Error fetching asset report');
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllRequestsReports();
  }, [])

  const getAssetReport = async (assetReportToken) => {
    try {
      const response = await postApi('plaid/get_asset_report', { assetReportToken });
      const { assetReportId, data } = response;
      // console.log("success assetReportId ", assetReportId);
      if (data) {
        navigate('/report', { state: data.report });
        setLoading(false);
      }
    } catch (error) {
      toast('please wait report is generating please try again some time later');
      setLoading(false);
    }
  };

  const deleteRequest = async (assetReportToken) => {
    try {

        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const data = await postApi('plaid/request/delete', { assetReportToken: assetReportToken }, headers);
        if (data.success) {
            toast.info("Request Delete Successfully ");
            getAllRequestsReports()
        } else {
            toast.error("Unable to delete request");
        }

    } catch (error) {
        console.error('Error occurred while fetching data', error);
        toast.error("Internal Server Error");

    }
};


  // Calculate current data to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = requestData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div>
        <body>
          <Adminsidebar />
          <section class="home-section">
            <AdminMobileSidebar />
            <div class="home-content">
              <div class="sales-boxes">
                <div class="recent-sales box">
                  <div class="title">Report Request </div>
                  <table class="table table-striped" style={{ marginTop: "25px" }}>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">User Name</th>
                        {/* <th scope="col">User Id</th> */}
                        <th scope="col">Request Id</th>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                        <th scope="col">Delete</th>

                      </tr>
                    </thead>
                    <tbody>
                      {currentItems && currentItems.map((item, index) => (
                        <tr key={item._id}>
                          <td scope="col">{indexOfFirstItem + index + 1}</td>
                          <td scope="col">{item.nameuser}</td>
                          {/* <td scope="col">{item.client_user_id}</td> */}
                          <td scope="col">{item.asset_report_id}</td>
                          <td scope="col">{new Date(item.createdAt).toLocaleDateString()}</td>
                          <td scope="col"><button className='btn btn-info' onClick={() => getAssetReport(item.assetReportToken)} >View Details</button></td>
                          <td scope="col">
                            <button className="btn  px-3 mx-2 btn-danger" onClick={() => { deleteRequest(item.assetReportToken) }}>Delete</button>
                            </td>
                        

                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button
                      className='btn btn-info'
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <button
                      className='btn btn-info'
                      onClick={() => paginate(currentPage + 1)}
                      disabled={indexOfLastItem >= requestData.length}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </body>
      </div>
    </>
  );
};

export default RequestReport;
