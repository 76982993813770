import './App.css';
import PublicRoutes from './Routes/PublicRoutes';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
   <ToastContainer/>
     <PublicRoutes/>
 
    </>
  );
}

export default App;
