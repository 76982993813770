import React, { useState } from 'react';
import { MdAccountBalance } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { postApi } from '../service/apiService';
import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';
const AssetReportPage = () => {
    const location = useLocation();
    const [selectedDays, setSelectedDays] = useState("0");
    const [tempData, setTempData] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let instituteData = location?.state;
    const token = localStorage.getItem('token');

    // console.log(instituteData)
    const data = instituteData ?? {
        institution: { name: 'N/A' }, accounts: []
    };

    // console.log("name", instituteData.institution.name)

    const handleChange = (event) => {
        setTempData(null);
        setSelectedDays(event.target.value); // Update the selected value state
    };

    const createAssetReport = async () => {
        try {
            setLoading(true);
            let days_requested = selectedDays ?? 90
            days_requested = Number(days_requested)
            const { accessToken, nameuser, emailuser, clientUserId, accounts } = instituteData;
            let accountLength = accounts?.length ?? 1;
            console.log("accountLength  ", accountLength)
            let reportToken = "";
            if (accessToken) {
                if (tempData === null) {
                    const response = await postApi('plaid/create_asset_report', { accessToken, nameuser, emailuser, clientUserId, days_requested });
                    const { error, assetReportId, assetReportToken, data } = response;
                    if (error) {
                        reportToken =""
                        toast("You already raised report request. please check  in Request Report section ")
                        setLoading(false);
                    }else {
                        sessionStorage.setItem("reportToken", assetReportToken);
                        reportToken = assetReportToken;
                        setTempData(assetReportToken);
                    }
                 
                } else {
                    reportToken = tempData;
                }

                if (reportToken !== "" && reportToken !== null) {
                    let time = days_requested * 200;

                    setTimeout(async () => {
                        await getAssetReport(reportToken, 0, time);
                    }, time);
                }

            } else {
                console.error('Access token not found');
                toast('Access token not found');
                setLoading(false);
            }
        } catch (error) {
            // console.log("error", error);
            toast('Error creating asset report');
            setLoading(false);
        }
    };


    const getAssetReport = async (assetReportToken, count = 3, time) => {
        try {
            const response = await postApi('plaid/get_asset_report', { assetReportToken });
            const { assetReportId, data } = response;
            // console.log("success assetReportId ", assetReportId);

            if (data) {
                setTempData(null);
                navigate('/report', { state: data.report });
                setLoading(false);
            }
        } catch (error) {
            toast('Request received. Please check the request section later.');

            setTimeout(() => {

                navigate('/home');

            }, [2000])
            setLoading(false);
        }
    };

    const deleteAccounts = async () => {
        try {

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const data = await postApi('plaid/account/delete', { accountId: instituteData._id }, headers);
            if (data.success) {
                toast.info("Account Delete Successfully ");

                setTimeout(() => {
                    navigate('/home');
                }, [2000])

            } else {
                toast.error("Unable to delete Account");
            }

        } catch (error) {
            console.error('Error occurred while fetching data', error);
            toast.error("Internal Server Error");

        }
    };


    return (
        <div>
            <Header />
            <section className="banner-section index">
                {loading && (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                )
                }
                <div className="overlay">

                    <div className="banner-content">
                        <div className="container wow fadeInUp">

                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-7 col-md-6">
                                    <div className="main-content">
                                        <div className="top-area section-text">
                                            {/* <h5 className="sub-title">Trusted by over 3M customers</h5> */}
                                            <h1 className="title">Create

                                                <span className="title" style={{
                                                    fontSize: "76px",
                                                    lineHeight: "98.8px",
                                                    color: '#00be90e8'
                                                }}>
                                                    {instituteData.institution.name}
                                                </span>  Asset Report</h1>

                                        </div>
                                        {/* <p className="xlr">Welcome to the next step in your mortgage application journey. We partner with Plaid, a trusted leader in financial data security, to ensure your banking information is handled with the utmost care and security. By authorizing your banking information through Plaid, you're allowing us to swiftly and securely verify your financial status, which is a critical component of the underwriting process for your mortgage loan.</p> */}
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-5 col-md-3 ">
                                    <div className="send-money d-flex flex-column justify-content-between" style={{ minHeight: "400px" }}>
                                        <div >
                                            <div className="py-2 d-flex justify-content-between ">
                                                <div className="left-side">
                                                    <span className="xlr fw-bold">Accounts Details</span>
                                                </div>
                                                <button className="btn  px-3 mx-2 btn-danger" onClick={() => { deleteAccounts() }}>Delete</button>

                                            </div>

                                            {data.accounts.map((account, index) => (
                                                <div key={index} className="container my-2 p-2" style={{ backgroundColor: "#edefff" }}>
                                                    <div className=" d-flex justify-content-between">
                                                        <div className="left-area">
                                                            <MdAccountBalance style={{ color: 'blue' }} />
                                                            <span className="xl mx-2">{account.name}</span>
                                                        </div>
                                                        <div className="right-area">
                                                            <span className="mdr">Subtype : {account.subtype}</span>
                                                        </div>
                                                    </div>
                                                    <div className=" d-flex justify-content-between">
                                                        <div className="left-area">
                                                            <span className="mdr mx-2"> Mask :{account.mask}</span>
                                                        </div>
                                                        <div className="right-area">
                                                            <span className="mdr">Type : {account.type}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="btn-area mt-2">

                                            <div className=" my-3 d-flex justify-content-between">
                                                <div className="items-center">
                                                    <span className="xl mx-2">Please select Period</span>
                                                </div>
                                                <div className="w-full" >
                                                    <select className="form-select" onChange={handleChange} value={selectedDays}>
                                                        <option value="0" >Days requested</option>
                                                        <option value="30">1 Month</option>
                                                        <option value="60">2 Month</option>
                                                        <option value="90">3 Month</option>
                                                        <option value="365">1 Year</option>
                                                        <option value="730">2 Year</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <button onClick={createAssetReport} disabled={selectedDays == "0"} className="btn cmn-btn w-100">Create your report</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AssetReportPage;