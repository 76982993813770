import React from 'react'
import { Link } from 'react-router-dom'
function ErrorPage() {
  return (
    <div><body>
    {/* <div class="preloader" id="preloader"></div> */}

    <a href="javascript:void(0)" class="scrollToTop"><i class="fas fa-angle-double-up"></i></a>

    <section class="error">
        <div class="overlay pt-120">
            <div class="container wow fadeInUp">
                <div class="row align-items-end justify-content-center">
                    <div class="col-xl-6">
                        <div class="section-text text-center">
                            <h2 class="title">Oops!Page Not Found</h2>
                            <p>We can’t seem to find the page you’re looking for</p>
                            <Link to="/" class="cmn-btn mt-30">Back To Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</body></div>
  )
}

export default ErrorPage