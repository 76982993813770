import React from "react";
import Header from "../Componets/Header";
import { useLocation } from "react-router-dom";


const ShowDetails = () => {
  const location = useLocation();

  const user = location?.state ?? {
    account_id: "azXeW3GaAVUW519GP1PjIo1kGq83QWhkRWZWg",
    balances: {
      available: 200,
      current: 210,
      iso_currency_code: "USD",
      limit: null,
      margin_loan_amount: null,
      unofficial_currency_code: null
    },
    days_available: 60,
    historical_balances: [
      { current: 210, date: "2024-03-13", iso_currency_code: "USD", unofficial_currency_code: null },
      { current: 210, date: "2024-03-12", iso_currency_code: "USD", unofficial_currency_code: null },
      { current: 210, date: "2024-03-11", iso_currency_code: "USD", unofficial_currency_code: null }
    ],
    mask: "1111",
    name: "Plaid Saving",
    official_name: "Plaid Silver Standard 0.1% Interest Saving",
    owners: [
      {
        addresses: [
          { data: { city: "Malakoff", country: "US", postal_code: "14236", region: "NY", street: "2992 Cameron Road" }, primary: true },
          { data: { city: "San Matias", country: "US", postal_code: "93405-2255", region: "CA", street: "2493 Leisure Lane" }, primary: false }
        ],
        emails: [
          { data: "accountholder0@example.com", primary: true, type: "primary" },
          { data: "accountholder1@example.com", primary: false, type: "secondary" }
        ],
        names: ["Alberta Bobbeth Charleson"],
        phone_numbers: [{ data: "1112223333", primary: false, type: "home" }]
      }
    ],
    ownership_type: null,
    subtype: "savings",
    transactions: [
      {
        account_id: "azXeW3GaAVUW519GP1PjIo1kGq83QWhkRWZWg",
        account_owner: null,
        amount: -4.22,
        category: ["Transfer", "Payroll"],
        category_id: "21009000",
        check_number: null,
        credit_category: { detailed: "INCOME_SALARY", primary: "INCOME" },
        date: "2024-01-20",
        date_transacted: "2024-01-20",
        iso_currency_code: "USD",
        location: { address: null, city: null, country: null, lat: null, lon: null, postal_code: null, region: null, store_number: null },
        merchant_name: null,
        name: "INTRST PYMNT",
        original_description: "INTRST PYMNT",
        payment_meta: { by_order_of: null, payee: null, payer: null, payment_method: null, payment_processor: null, ppd_id: null, reason: null, reference_number: null },
        pending: false,
        pending_transaction_id: null,
        transaction_id: "NoNRxnyQd1IxoXAdGXGmI7z4QMEoa3sXLNbJZ",
        transaction_type: "special",
        unofficial_currency_code: null
      },
      {
        account_id: "azXeW3GaAVUW519GP1PjIo1kGq83QWhkRWZWg",
        account_owner: null,
        amount: 25,
        category: ["Payment", "Credit Card"],
        category_id: "16001000",
        check_number: null,
        credit_category: { detailed: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT", primary: "LOAN_PAYMENTS" },
        date: "2024-01-25",
        date_transacted: "2024-01-24",
        iso_currency_code: "USD",
        location: { address: null, city: null, country: null, lat: null, lon: null, postal_code: null, region: null, store_number: null },
        merchant_name: null,
        name: "CREDIT CARD 3333 PAYMENT *//",
        original_description: "CREDIT CARD 3333 PAYMENT *//",
        payment_meta: { by_order_of: null, payee: null, payer: null, payment_method: null, payment_processor: null, ppd_id: null, reason: null, reference_number: null },
        pending: false,
        pending_transaction_id: null,
        transaction_id: "49breVdQ48iZ84wve4eqhmxzDbBkqesl8Eqyr",
        transaction_type: "special",
        unofficial_currency_code: null
      }
    ],
    type: "depository"
  }

  // location.state;
  // console.log(user);



  return (
    <>
      <Header />
      <section className="">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-header dark-sec text-center">
                  <h3 className="text-center">{user?.name} REPORT .</h3>
                </div>
              </div>
              <div>
                <h5 className="mx-2 " >
                  Account Overview .
                </h5></div>
              <div
                className="d-flex flex-row my-2 justify-content-between"
               
              >
                <div >
                  <p className="">
                    INSTITUTION :{user?.institution_name}
                  </p>
                </div>
                <div>
                  <p className="">
                    ACCOUNT NAME: {user?.official_name}
                  </p>
                </div>
                <div>
                  <p className="">ACCOUNT MASK : {user?.mask}.</p>
                </div>
              </div>
              <div className="col-lg-12 row">
                <div className="col-lg-6 ">
                  <p className=''>Report Information</p>
                  <div class="container">
                    <div class="row  py-2 border-bottom ">
                      <div class="col">report ID</div>
                      <div class="col">{user.asset_report_id}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Requested on</div>
                      <div class="col">{new Date(user?.date_generated ?? new Date()).toISOString().split('T')[0]}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Days requested</div>
                      <div class="col">{user.days_requested}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className=''>Account Holder Information </p>
                  {user.owners.map((owner, index) => (
                    <div key={index} class="container">
                      <div class="row  py-2 border-bottom ">
                        <div class="col">Names : </div>
                        <div class="col">
                          {owner.names.map((name, index) => ( <div key={index} className="py-2"> {name} </div> ))}
                        </div>
                      </div>
                      <div class="row py-2 border-bottom">
                        <div class="col">Addresses</div>
                        <div class="col">
                          {owner.addresses.map((address, index) => ( <div key={index} className="py-2"> {address.data.street} , {address.data.city} , {address.data.region}, {address.data.postal_code} , {address.data.country}</div> ))}
                        </div>
                      </div>

                      <div class="row py-2 border-bottom">
                        <div class="col">Phone numbers</div>
                        <div class="col">
                          {owner.phone_numbers.map((phone, index) => (<div key={index} className="py-2"> {phone.data} </div> ))}
                        </div>
                      </div>
                      <div class="row py-2 border-bottom">
                        <div class="col">Emails</div>
                        <div class="col">
                          {owner.emails.map((email, index) => ( <div key={index} className="py-2"> {email.data} </div> ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* {user?.data?.report?.items.map((item, index) => (
                <div className="col-lg-12 row" key={index} style={{ marginTop: '36px' }}>
                  <div className="col-lg-6 ">
                    <p className="">Report Information</p>
                    <p className="">Provided by the requester</p>
                    <table className="table table-bordered">
                      <thead>
                        <tr className="">
                          <th scope="col" style={{ fontSize: "16px" }}>
                            Account Name
                          </th>
                          <th scope="col" style={{ fontSize: "16px" }}>
                            Account Type
                          </th>
                          <th scope="col" style={{ fontSize: "16px" }}>
                            Account Subtype{" "}
                          </th>
                          <th scope="col" style={{ fontSize: "16px" }}>
                            Day Available
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item.accounts[0].name}</td>
                          <td>{item.accounts[0].type}</td>
                          <td>{item.accounts[0].subtype}</td>
                          <td style={{ fontSize: "16px" }}>
                            {item.accounts[0].days_available}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-6 ">
                    <p className="">Account Holder Information</p>
                    <p className="">Sourced from the institution</p>

                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead
                          className=""
                          style={{ fontSize: "16px" }}
                        >
                          <tr style={{ fontSize: "16px" }}>
                            <th scope="col" style={{ fontSize: "16px" }}>
                              Owner Name
                            </th>
                            <th scope="col" style={{ fontSize: "16px" }}>
                              Emails
                            </th>
                            <th scope="col" style={{ fontSize: "16px" }}>
                              Phone Numbers
                            </th>
                            <th scope="col" style={{ fontSize: "16px" }}>
                              Primary Address
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className=""
                          style={{ fontSize: "16px" }}
                        >
                          <tr style={{ fontSize: "16px" }}>
                            <td>{item.accounts[0].owners[0].names[0]}</td>
                            <td>
                              {item.accounts[0].owners[0].emails.map(
                                (email, index) => (
                                  <div key={index}>{email.data}</div>
                                )
                              )}
                            </td>
                            <td>
                              {item.accounts[0].owners[0].phone_numbers.map(
                                (phone, index) => (
                                  <div key={index}>{phone.data}</div>
                                )
                              )}
                            </td>
                            <td>
                              {
                                item.accounts[0].owners[0].addresses.find(
                                  (address) => address.primary
                                ).data.street
                              }
                              ,{" "}
                              {
                                item.accounts[0].owners[0].addresses.find(
                                  (address) => address.primary
                                ).data.city
                              }
                              ,{" "}
                              {
                                item.accounts[0].owners[0].addresses.find(
                                  (address) => address.primary
                                ).data.region
                              }
                              ,{" "}
                              {
                                item.accounts[0].owners[0].addresses.find(
                                  (address) => address.primary
                                ).data.postal_code
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))} */}

              {/* Account Summary */}
              <div className="col-lg-12" style={{ marginTop: "25px" }} >
                <p className="">Account Summary</p>
                <p className="">Sourced from the Account</p>
                <div class="container">
                  <div class="d-flex flex-row justify-content-between border-bottom">
                    <div class="p-2">Current Balance</div>
                    <div class="p-2">{user.balances.current}</div>
                  </div>
                  <div class="d-flex flex-row justify-content-between border-bottom">
                    <div class="p-2">Available Balance</div>
                    <div class="p-2">{user.balances.available}</div>
                  </div>
                  <div class="d-flex flex-row justify-content-between border-bottom">
                    <div class="p-2">Type</div>
                    <div class="p-2">{user.type}</div>
                  </div>
                </div>
              </div>

              {/* Account History */}

              <div className="col-lg-12" style={{ marginTop: "25px" }} >
                <p className="">Account History</p>
                <p className="">Sourced from the Account</p>
                <table className="table table-bordered">
                  <thead className="">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Date</th>
                      <th scope="col">Description</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Type</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {user.transactions.map(
                      (transaction, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{transaction.date}</td>
                          <td>{transaction.name}</td>
                          <td>{transaction.amount}</td>
                          <td>{transaction.transaction_type}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShowDetails;
