import React, { useState, useEffect } from 'react';
import './Admin.css';
import { Link, useNavigate } from 'react-router-dom';
import Adminsidebar from './Adminsidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import { getApi } from '../../service/apiService';
import { ToastContainer , toast } from 'react-toastify';
function NewConnecteUser() {
    const [accountData, setAccountData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
      const fetchUsersAccounts = async () => {
      

        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              };
            const data = await getApi('plaid/get_all_linked_account' , {headers});
            // console.log('Data loaded successfully', data);
            setAccountData(data)
        } catch (error) {
            console.error('Error occurred while fetching data', error);
            toast.error("Error occurred while fetching data");

        }
    };

    fetchUsersAccounts();
    }, []);


    const fetchSearchAccounts = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            let searchData = searchValue.trim()
            // Check if the search value is empty
            if (!searchValue|| searchData.length === 0 ) {
                // Fetch all reports
                const data = await getApi('plaid/get_all_linked_account', { headers });
                setAccountData(data);
            } else {
                const queryParams = encodeURIComponent(searchData);
                const apiUrl = `plaid/get_all_linked_account?term=${queryParams}`;
                const data = await getApi(apiUrl, { headers });
                setAccountData(data);
            }
            setCurrentPage(1)
        } catch (error) {
            console.error('Error occurred while fetching data', error);
        }
    };


    const totalPages = Math.ceil(accountData.length / 5);

    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    const currentData = accountData.slice(startIndex, endIndex);

    // Event handler for next page
    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    // Event handler for previous page
    const prevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const handleData = (data) => {
        navigate('/createReport', { state: data });
    };
    // navigate('/report', { state: data.report });

    // console.log("accountdata" , accountData)

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };
    
    return (
        <>
            <body>
                <Adminsidebar />
                <section className="home-section">
                    <AdminMobileSidebar />
                    <div className="home-content">
                        <div className="overview-boxes">
                            <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Total Linked Accounts</div>
                                    <div className="number">{accountData.length}</div>
                                    <div className="indicator">
                                        <i className='bx bx-up-arrow-alt'></i>
                                        <span className="text">Up from yesterday</span>
                                    </div>
                                </div>
                                <i className='bx bxs-cart-add cart two'></i>
                            </div>
                        </div>
                        <div className="sales-boxes">
                            <div className="recent-sales box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="title">Linked Accounts</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                                        <input placeholder='Search by Name & Email' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                        <button className='"btn btn-success mx-2' style={{padding:'10px', border:'1px solid black' , borderRadius:'10px'}} onClick={fetchSearchAccounts}>Search</button>
                                    </div>
                                    
                                </div>
                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Institute Name</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">User Email</th>

                                            {/* <th scope="col">Access Token</th> */}
                                            <th scope="col">Generated Date</th> 

                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData.map((account, index) => (
                                            <tr key={index}>
                                                <th scope="row">{startIndex + index + 1}</th>
                                                <td> {account.institution.name} </td>
                                                 <td>{ account.nameuser && account.nameuser}</td> 
                                                 <td>{account.emailuser && account.emailuser}</td> 
                                                 <td>{formatDate(account.createdAt)}</td>
                                                <td><button   className=" btn btn-info" onClick={() => handleData(account)}>View Details</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="button">
                                    {currentPage > 1 && <button    className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                    {currentPage < totalPages && <button    className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </body>
        </>
    );
}

export default NewConnecteUser