import React, { useState, useEffect } from 'react';
import './Admin.css';
import { RxDashboard } from 'react-icons/rx';
import { SiGoogleforms } from 'react-icons/si';
import { CiCircleList } from 'react-icons/ci';
import { IoIosSettings } from 'react-icons/io';
import { CiLogout } from 'react-icons/ci';
import { GrUserAdmin } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';

function AdminMobileSidebar() {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
    window.localStorage.clear();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // Navigate to 404 page if token is not present
    }
  }, []);

  return (
    <>
      <nav>
        <div class="sidebar-button">
          <i class='bx bx-menu sidebarBtn'></i>
          <span class="dashboard">
            <img  style={{maxWidth:"7%"}} src='/assets/images/MCM%20House%20(2).png' />
          </span>
        </div>
        <div class="mobileham" style={{marginRight:'179px'}}>
          <Hamburger toggled={isOpen} toggle={setOpen}  style={{innerWidth:'210px'}}/>
        </div>
        { isOpen &&
          <div className='mobileSidbar'>
            <div style={{position:"relative" , left: "9px" , color:'white'}} className='mobileham'>
              <Hamburger toggled={isOpen} toggle={setOpen}   />
            </div>
            <div className='mobileNavcontainer'>
              <ul class="nav-links">
                <li className='my-3' style={{color:'white'}}>
                  <Link to="/home" class="active" style={{color:'white'}}>
                    <RxDashboard  class='mx-3' style={{color:'white'}} />
                    <span class="links_name mx-3"  style={{color:'white'}}>Dashboard</span>
                  </Link>
                </li>
                <li className='my-3'  style={{color:'white'}}>
                  <Link to="/usersList">
                    <SiGoogleforms class='mx-3'  style={{color:'white'}}/>
                    <span class="links_name mx-3"  style={{color:'white'}}>Total Users</span>
                  </Link>
                </li>
                <li className='my-3'    style={{color:'white'}}>
                  <Link to="/reports">
                    <CiCircleList  
                      style={{color:'white'}}
                      class='mx-3'
                    />        
                    <span class="links_name mx-3"  style={{color:'white'}}>Total Reports</span>
                  </Link>
                </li>
                <li className='my-3'    style={{color:'white'}}>
                  <Link to="/accounts">
                    <CiCircleList  
                      style={{color:'white'}}
                      class='mx-3'
                    />        
                    <span class="links_name mx-3"  style={{color:'white'}}> Linked Accounts</span>
                  </Link>
                </li>
                <li className='my-3' style={{color:'white'}}>
                  <Link to="/settings">
                    <IoIosSettings 
                      class='mx-3'
                      style={{color:'white'}}
                    />
                    <span class="links_name mx-3"  style={{color:'white'}}>Setting</span>
                  </Link>
                </li>
                <li className='my-3' style={{color:'white'}}>
                  <a onClick={handleLogout}>
                    <CiLogout  
                      class='mx-3 text-white'
                    />
                    <span class="links_name mx-3"  style={{color:'white'}}>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        }
        <div class="profile-details">
          <img src="https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1114445501.jpg" alt="" />
          <span class="admin_name"  style={{color:'black'}}>Admin </span>
          <i class='bx bx-chevron-down' ></i>
        </div>
      </nav>
    </>
  );
}

export default AdminMobileSidebar;
