import React, { useState, useEffect } from 'react'
import './Admin.css'
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";
import { getApi } from '../../service/apiService';
import { Link, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import Adminsidebar from './Adminsidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import { ToastContainer , toast } from 'react-toastify';
function AdminHome() {

  const [usersData, setUsersData] = useState([]);
  const [accountData, setAccountData] = useState({});
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        if (!token) {
          toast("Please Login again");
          setLoading(false);
          return;
        }
  
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        const data = await getApi('users/allUsers' ,  {headers});
        // console.log('Data loaded successfully', data);
        setUsersData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error occurred while fetching data', error);
        toast.error("Error occurred while fetching data");
        setLoading(false);
      }
    };
  
    const fetchGetRecord = async () => {
      setLoading(true);
      try {
        if (!token ) {
          setLoading(false);
          return;
        }
  
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
  
        const data = await getApi('users/getRecord' , {headers});
        // console.log('Data loaded successfully', data);
        setAccountData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error occurred while fetching data', error);
        toast.error("Error occurred while fetching data");

        setLoading(false);
      }
    };
  
    fetchUserData();
    fetchGetRecord();
  }, []); 
  


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // Navigate to 404 page if token is not present
    } else {

    }
  }, []);



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };


  // console.log(accountData.length)

  return (
    <>


      <body >
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )
        }
        <Adminsidebar />
        <section class="home-section">
          <AdminMobileSidebar />
          <div class="home-content">
            <div class="overview-boxes">
              <div class="box">
                <div class="right-side">
                  <div class="box-topic"> Total Reports</div>
                  <div class="number" style={{ fontSize: '26px' }}>{accountData.reports ?? 0}</div>
                  <div class="indicator">
                    <i class='bx bx-up-arrow-alt'></i>
                    <span class="text">Up from today</span>
                  </div>
                </div>
                <i class='bx bx-cart-alt cart'></i>
              </div>
              <div class="box">
                <div class="right-side">
                  <div class="box-topic">Total Accounts</div>
                  <div class="number">{ accountData.accounts ?? 0 }</div>
                  <div class="indicator">
                    <i class='bx bx-up-arrow-alt'></i>
                    <span class="text">Up from yesterday</span>
                  </div>
                </div>
                <i class='bx bx-cart cart three' ></i>
              </div>
              <div class="box">
                <div class="right-side">
                  <div class="box-topic">Admin account</div>
                  <div class="number">{accountData.users ?? 0 }</div>
                  <div class="indicator">
                    <i class='bx bx-down-arrow-alt down'></i>
                    <span class="text">Down From Today</span>
                  </div>
                </div>
                <i class='bx bxs-cart-download cart four' ></i>
              </div>
            </div>

            <div class="sales-boxes">
              <div class="recent-sales box">
                <div class="title">admin Accounts</div>
                <table class="table table-striped" style={{ marginTop: '25px' }}>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">User Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone number</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.slice(0, 5).map((user, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{user.first_name} {user.middle_name} {user.last_name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                      <td>{formatDate(user.createdAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div class="button">
                  <Link to="/usersList">See All</Link>
                </div>
              </div>


            </div>
          </div>
        </section>


      </body>


    </>

  )
}

export default AdminHome