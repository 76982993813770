import React, { useCallback, useState, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { postApi } from '../service/apiService';
import { ToastContainer , toast } from 'react-toastify';
const SimplePlaidLink = ({ handelChangePublicToken }) => {
    const [token, setToken] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    async function  exchangePublicToken(public_token, metadata ,name, email){
        const nameuser = localStorage.getItem('name');
        const emailuser = localStorage.getItem('email');
        

        try {
            // console.log("Received name and email:", nameuser, emailuser);
          
            const clientUserId = localStorage.getItem('clientUserId');
            const response = await postApi('plaid/exchange_public_token', { public_token, metadata, nameuser, emailuser  , clientUserId});
            const { result } = response;
            if (result) {
                handelChangePublicToken(metadata);
            }
        } catch (error) {
            // console.log("error", error);
            toast(" Internal server Error please try again some time later ");
        }
    };

    const onSuccess = useCallback((publicToken, metadata) => {
        exchangePublicToken(publicToken, metadata ,name, email);
    }, [name ,email]);

    const { open, ready } = usePlaidLink({
        token,
        onSuccess,
    });

    const createLinkToken = async () => {
        try {
            const response = await postApi('plaid/create_link_token', {});
            const { token , clientUserId } = response;
            localStorage.setItem('clientUserId', clientUserId);
            setToken(token.link_token);
        } catch (error) {
            toast(" Internal server Error please try again some time later ");
            // console.log("error", error);
        }
    };

    useEffect(() => {
        createLinkToken();
        // Cleanup function to remove name and email from localStorage when component unmounts
    }, []);


    const handleSaveChanges = () => {
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);

        if( localStorage.getItem('name').length>1 && localStorage.getItem('email').length>1 && emailValidation){
            open();

        }
        else{
            toast("Please enter your name or correct email");
        }
    };



    const emailValidation = regex.test(email);

    return (
        <section className="banner-section corporate-card budgeting-analytics">
            <div className="overlay">
                <div className="banner-content px-5">
                    <div className="row justify-content-between align-items-center " style={{ marginTop: "-20px" }}>
                        <div className="col-lg-8 col-md-8 px-4">
                            <div className="main-content">
                                <div className="top-area section-text">
                                    <h1 className="title">Secure Your Mortgage with Ease</h1>
                                    <p className="xlr">Welcome to the next step in your mortgage application journey. We partner with Plaid, a trusted leader in financial data security, to ensure your banking information is handled with the utmost care and security. By authorizing your banking information through Plaid, you're allowing us to swiftly and securely verify your financial status, which is a critical component of the underwriting process for your mortgage loan.</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div class="send-money"  >
                                <div class="container  py-4 px-2" style={{ backgroundColor: "#edefff" }} >
                                    <div className="left-side">
                                        <span className="xlr fw-bold">Fill User Details</span>
                                    </div>
                                  
                                    <div className=" my-4">
                                        <label class="" htmlFor="logemail">Your Name</label>
                                        <input type="text" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)}  />
                                      { name  ==''&& <div className="text-danger small">Please enter your name.</div>}
                                    </div>
                                    <div className="single-input my-4">
                                        <label htmlFor="logpassword">Your Email</label>
                                        <input type="email" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        {email  !=='' &&  !emailValidation&& <div className="text-danger small">Please enter correct email.</div>}
                                        { email  =='' && <div className="text-danger small">Please enter your email.</div>}
                                    </div>
                                    <div className="bottom-area d-xxl-flex my-4">
                                        <div className="bottom-area d-xxl-flex">
                                            <button className="cmn-btn"  onClick={handleSaveChanges}>
                                                Connect a bank account
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SimplePlaidLink;
