import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from '../Pages/Login';
import Dashboard from '../Pages/Dashboard';
import Register from '../Pages/Register';
import Accountreport from '../Pages/Accountreport';
import AdminHome from '../Componets/Admin/AdminHome';
import ShowDeteils from '../Pages/ShowDeteils';
import ErrorPage from '../Componets/Admin/ErrorPage';
import AdminUserList from '../Componets/Admin/AdminUserList';
import AccountUserDeteils from '../Componets/Admin/AccountUserDeteils';
import NewConnecteUser from '../Componets/Admin/NewConnecteUser';
import AdminSetting from '../Componets/Admin/AdminSetting';
import AssetReportPage from '../Componets/AssetReport';
import RequestReport from '../Pages/RequestReport';

  function PublicRoutes() {
    return (
      <>
        <Router>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<Dashboard />} />
            <Route path='/report' element={<Accountreport />} />
            <Route path='/createReport' element={<AssetReportPage />} />
            <Route path='/home' element={<AdminHome/>} />
            <Route path='/details' element={<ShowDeteils/>} />
            <Route path='/404' element={<ErrorPage/>} />
            <Route path='/usersList' element={<AdminUserList/>} />
            <Route path='/reports' element={<AccountUserDeteils/>} />
            <Route path='/accounts' element={<NewConnecteUser/>} />
            <Route path='/settings' element={<AdminSetting/>} />
            <Route path='/requestReport' element={<RequestReport/>} />
          </Routes>
        </Router>
      </>
    )
  }

  export default PublicRoutes