import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Componets/Header';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postApi } from '../service/apiService';

function Login() {
    const Naviagte = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading , setLoading] = useState(false)



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await postApi('users/login', { email, password });
            
            if (response.status === 200) {
               // // console.log('Login successful', response);
               // // console.log('Login successful', response.data.token);

                localStorage.setItem('token', response.data.token);
                setLoading(false);
                Naviagte('/home');
            } else {
                setError( 'An error occurred while logging in');
                toast('Please enter a correct email and password');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error occurred while logging in', error);
            setError('Something went wrong, please try again');
            toast('Something went wrong, please try again');
            setLoading(false);
        }
    };
    

    return (
        <div>
            <Header/>
            {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )
}
            <section className="login-reg " style={{marginTop:'122px'}}>
                <div className="overlay pt-150">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-5 pt-4 ">
                                <div className="section-text text-center">
                                    <h3 className="title">Log in to Continue</h3>
                                    {/* <p className="dont-acc">Don’t have an account? </p> */}
                                    {/* <span className="or">Or Continue With</span> */}
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="single-input">
                                                <label htmlFor="logemail">Your Email</label>
                                                <input type="text" id="logemail" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                { email  ==''&& <div className="text-danger small">Required field</div>}
                                            </div>
                                            <div className="single-input">
                                                <label htmlFor="logpassword">Your Password</label>
                                                <input type="password" id="logpassword" placeholder="Enter Your Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                { password  ==''&& <div className="text-danger small">Required field</div>}
                                            </div>
                                            <button type="submit" className="cmn-btn w-100">Login</button>
                                        </div>
                                    </div>
                                </form>
                                {/* {error && <div className="error-message " style={{color:'red'}}>{error}</div>} */}
                                {/* <div className="forgot-pass mt-30 text-center">
                                    <a href="javascript:void(0)">Forgot Password</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;
