import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Header() {

    const navigate = useNavigate();

    const handelGoToHome = () => {
        navigate('/');
    }

    return (

        <header class="header-section" style={{ backgroundColor: '#7370ca' }}>
            <div class="container">
                <div class="row d-flex header-area">
                    <nav class="navbar navbar-expand-lg navbar-light" onClick={handelGoToHome}>
                        <img src="assets/images/MCM House (2).png" className="logo customeLogog" alt="logo" />
                    </nav>
                </div>
            </div>
        </header>

    )
}

export default Header