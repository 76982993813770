import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postApi } from '../service/apiService';

function Register() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!firstName || !lastName || !email || !password || !phoneNumber) {
            toast('All fields are required');
            return;
        }

        try {
            const response = await postApi('users/signup', { first_name: firstName, middle_name: middlename, last_name: lastName, email, password, phone_number: phoneNumber });
            const { token, error = null } = response;

            if (error == null) {
                // console.log('Registration successful',);
                // Redirect to home page after successful registration
                toast('User Added successful');
                setFirstName('')
                setMiddlename('')
                setLastName('')
                setEmail('')
                setPassword('')
                setPhoneNumber('')

            } else {
                setError('An error occurred while adding user');
                toast('An error occurred while adding user');
            }
        } catch (error) {
            console.error('Error occurred while adding user', error);
            setError('An error occurred while adding user');
            toast('An error occurred while adding user');
        }
    };

    return (
        <div>
            <div className=" pt-120  ">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-5 bg-white p-3">
                        <div className="section-text ">
                            <h4 className="sub-title">Add new User</h4>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="single-input">
                                        <label htmlFor="firstname">First Name</label>
                                        <input type="text" id="firstname" placeholder="Enter Your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                        {firstName == '' && <div className="text-danger small">Required field</div>}
                                    </div>
                                    <div className="single-input">
                                        <label htmlFor="firstname">Middle Name</label>
                                        <input type="text" id="middlename" placeholder="Enter Your Middle Name" value={middlename} onChange={(e) => setMiddlename(e.target.value)} />

                                    </div>
                                    <div className="single-input">
                                        <label htmlFor="lastname">Last Name</label>
                                        <input type="text" id="lastname" placeholder="Enter Your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                        {lastName == '' && <div className="text-danger small">Required field</div>}
                                    </div>
                                    <div className="single-input">
                                        <label htmlFor="email">Your Email</label>
                                        <input type="email" id="email" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        {email == '' && <div className="text-danger small">Required field</div>}
                                    </div>
                                    <div className="single-input">
                                        <label htmlFor="password">Your Password</label>
                                        <input type="password" id="password" placeholder="Enter Your Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                        {password == '' && <div className="text-danger small">Required field</div>}
                                    </div>
                                    <div className="single-input">
                                        <label htmlFor="phonenumber">Phone Number</label>
                                        <input type="tel" id="phonenumber" placeholder="Enter Your Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                                        {phoneNumber == '' && <div className="text-danger small">Required field</div>}
                                    </div>
                                    <button type="submit" className="cmn-btn my-3 w-100">Add</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

            <ToastContainer />
        </div>
    );
}

export default Register;
