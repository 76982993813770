import React, { useState, useEffect } from 'react';
import './Admin.css';
import { RxDashboard } from 'react-icons/rx';
import { SiGoogleforms } from 'react-icons/si';
import { CiCircleList } from 'react-icons/ci';
import { IoIosSettings } from 'react-icons/io';
import { CiLogout } from 'react-icons/ci';
import { GrUserAdmin } from 'react-icons/gr';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';

function Adminsidebar() {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    navigate('/login');
    window.localStorage.clear();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/404'); // Navigate to 404 page if token is not present
    }
  }, []);

  return (

      <div class="sidebar">
        <div class="logo-details">
          <i class='bx bxl-c-plus-plus'></i>
          <GrUserAdmin style={{ color: 'white' }} />
          <span class="logo_name mx-3" style={{ fontSize: '15px' }}>Admin</span>
        </div>
        <ul class="nav-links">
          <li>
            <Link to="/home" className={location.pathname === '/home' ? 'active' : ''}>
              <RxDashboard class='mx-3' style={{ color: 'white' }} />
              <span class="links_name mx-3">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/reports" className={location.pathname === '/reports' ? 'active' : ''}>
              <SiGoogleforms class='mx-3' style={{ color: 'white' }} />
              <span class="links_name mx-3">Total Reports</span>
            </Link>
          </li>
          <li>
            <Link to="/accounts" className={location.pathname === '/accounts' ? 'active' : ''}>
              <CiCircleList style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3">Linked Accounts</span>
            </Link>

          </li>
          <li>
            <Link to="/requestReport" className={location.pathname === '/requestReport' ? 'active' : ''}>
              <IoIosSettings style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3">Request Report</span>
            </Link>
          </li>
          <li>
            <Link to="/settings" className={location.pathname === '/settings' ? 'active' : ''}>
              <IoIosSettings style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3">Setting</span>
            </Link>
          </li>
          <li>
            <a onClick={handleLogout} style={{ color: 'white', cursor: 'pointer' }}>
              <CiLogout style={{ color: 'white' }} class='mx-3' />
              <span class="links_name mx-3">Logout</span>
            </a>
          </li>
        </ul>
      </div>
   
  );
}

export default Adminsidebar;
