let API_URL = 'https://www.mcmbankverify.com/api'
export const getApi = async (apiUrl, reqHeaders = {}) => {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token ?? 'demo'}`,
        ...reqHeaders,
    };
    try {
        const response = await fetch(`${API_URL}/${apiUrl}`, headers);
        if (!response.ok) {
          
            throw new Error(`Error fetching data: ${response}`);
        } else {
            const data = await response.json();
            return data;
        }

    } catch (error) {
        throw new Error(`Error fetching data: ${error.message}`);
    }
};

export const postApi = async (

    apiUrl,
    requestBody,
    reqHeaders = {},
) => {
    const token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token ?? 'demo'}`,
        ...reqHeaders,
    };
    try {
        const response = await fetch(`${API_URL}/${apiUrl}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(requestBody),
        });
        if (!response.ok) {
            throw new Error(`Error fetching data: ${response}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error posting data: ${error.message}`);
    }
};