import React, { useEffect, useState } from 'react';
import Header from '../Componets/Header';
import SimplePlaidLink from '../Componets/SimplePlaidLink';
import { FaArrowsRotate, FaDownload } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { postApi } from '../service/apiService';
import { toast } from 'react-toastify';

const dummyData = {
  asset_report_id: "N/A",
  client_report_id: "N/A",
  date_generated: "2024-03-14T20:06:23Z",
  days_requested: 0,
  items: [
    {
      accounts: [
        {
          account_id: "N/A",
          balances: {
            available: 0,
            current: 0,
            iso_currency_code: "N/A",
            limit: null,
            margin_loan_amount: null,
            unofficial_currency_code: null
          },
          days_available: 0,
          historical_balances: [],
          mask: "N/A",
          name: "N/A",
          official_name: "N/A",
          owners: [
            {
              addresses: [],
              emails: [],
              names: ["N/A"],
              phone_numbers: [{ data: "N/A", primary: false, type: "home" }]
            }
          ],
          ownership_type: null,
          subtype: "N/A",
          transactions: [],
          type: "N/A"
        }
      ],
      date_last_updated: "2024-03-14T20:05:25Z",
      institution_id: "N/A",
      institution_name: "N/A",
      item_id: "N/A"
    }
  ],
  user: {
    client_user_id: "N/A",
    email: "N/A",
    first_name: "N/A",
    last_name: "N/A",
    middle_name: "N/A",
    phone_number: "N/A",
    ssn: "N/A"
  }
}

const Accountreport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(location?.state ?? dummyData);
  const token = localStorage.getItem('token');
  const [tempData, setTempData] = useState(null);
  //  const history = useHistory();

  const handleViewDetails = (details) => {
    // console.log(details)
    let data = {
      asset_report_id: report.asset_report_id,
      date_generated: report.date_generated,
      days_requested: report.days_requested,
      institution_name: report.items[0].institution_name,
      ...details
    }
    navigate('/details', { state: data });
  };

  const getReportPDF = async () => {
    try {
      const assetReportToken = sessionStorage.getItem("reportToken")
      const response = await postApi('plaid/get_report_Pdf', { assetReportToken });
      const { data } = response
      const base64String = data; // Replace with your Base64 string
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'AssetReportPdf.pdf'; // Specify the file name here
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      // console.log("error", error)
    };
  };

  const getAssetReport = async (assetReportToken) => {
    try {
      const response = await postApi('plaid/get_asset_report', { assetReportToken });
      const { assetReportId, data } = response;
      // console.log("success assetReportId ", assetReportId);
      if (data && data.report) {
        setReport(data.report)
        setLoading(false);
      } else {
        console.error('Report data not found in response:', response);
        toast('Request received. Please check the request section later.');
        setLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while fetching asset report:", error);
      toast('Request received. Please check the request section later.');
      setLoading(false);
    }
  };

  const handleRefreshReport = async () => {
    try {
      setLoading(true);
      const assetReportToken = report.assetReportToken;
      const clientReportId = report.client_report_id;
      const user = report.user;
      let reportToken = "";
      if (clientReportId && assetReportToken) {
        if (tempData === null) {

          const response = await postApi('plaid/get_asset_report_refresh', { clientReportId, user, assetReportToken, daysRequested: 365 });

          if (response.error) {
              reportToken =""
              toast("You already raised report request. please check  in Request Report section ")
              setLoading(false);
          }else {
            sessionStorage.setItem("reportToken", response.assetReportToken);
            reportToken = response.assetReportToken;
            setTempData(reportToken);
          }
          // const { assetReportId, assetReportToken, data } = response;
        
        } else {
          reportToken = tempData;
        }

        if(reportToken != "")  {
          let time = report.days_requested * 200
          setTimeout(async () => {
            await getAssetReport(reportToken, 0, time);
          }, time);
        }
      
      } else {
        console.error('Access token not found');
        toast('Access token not found');
        setLoading(false);
      }
    } catch (error) {
      // console.log("error", error);
      toast("Error while Refresh asset report");
      setLoading(false);
    }
  };

  const deleteReport = async () => {
    try {

      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const data = await postApi('plaid/report/delete', { reportId: report._id }, headers);
      if (data.success) {
        toast.info("Report Delete Successfully ");

        setTimeout(() => {
          navigate('/home');
        }, [2000])

      } else {
        toast.error("Unable to delete Report");
      }

    } catch (error) {
      console.error('Error occurred while fetching data', error);
      toast.error("Internal Server Error");

    }
  };

  return (
    <>
      <section className="" >
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <Header />
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-header dark-sec text-center">
                  <h3 > {report.items[0].institution_name} REPORT</h3>
                </div>
              </div>
              <div className='d-flex flex-row  justify-content-between'>
                <h5 className="mx-2" style={{ marginLeft: "20px" }}>OverView .</h5>
                <div>
                  <button onClick={handleRefreshReport} className="btn px-2 me-2 btn-primary"><FaArrowsRotate /><span className="mx-2 text-white">Refresh</span></button>
                  <button onClick={getReportPDF} className="btn px-3  btn-primary"><FaDownload /><span className="mx-2 text-white">Pdf</span></button>
                  <button className="btn p-2 px-3 mx-2 btn-danger" onClick={() => deleteReport()}>Delete</button>
                </div>
              </div>
              <div className="col-lg-12 row">
                <div className="col-lg-6 ">
                  <p className=''>Report Information</p>
                  <div class="container">
                    <div class="row  py-2 border-bottom ">
                      <div class="col">report ID</div>
                      <div class="col">{report.asset_report_id}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">user ID</div>
                      <div class="col">{report.client_report_id}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Requested on</div>
                      <div class="col">{new Date(report.date_generated).toISOString().split('T')[0]}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Days requested</div>
                      <div class="col">{report.days_requested}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Accounts</div>
                      <div class="col">{report.items[0].accounts.length}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className=''>Borrower Information </p>
                  <div class="container">
                    <div class="row  py-2 border-bottom ">
                      <div class="col">First name</div>
                      <div class="col">{report.user.first_name}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Middle name</div>
                      <div class="col">{report.user.middle_name}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Last name</div>
                      <div class="col">{report.user.last_name}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Phone number</div>
                      <div class="col">{report.user.phone_number}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">SSN</div>
                      <div class="col">{report.user.ssn}</div>
                    </div>
                    <div class="row py-2 border-bottom">
                      <div class="col">Email</div>
                      <div class="col">{report.user.email}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 " style={{ marginTop: '25px' }}>
                <p className=''> Asset Summary </p>
                <p className=''>Sourced from the Account
                </p>
                <table className="table table-bordered">
                  <thead className=''>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Account</th>
                      <th scope="col">Account Mask</th>
                      <th scope="col"> Balances</th>
                      <th scope="col">Details</th>
                      {/* <th scope="col">Transaction ID</th> */}
                    </tr>
                  </thead>
                  <tbody className=''>
                    {report.items && report.items.map((item, index) => (
                      item.accounts.map((account, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{account.official_name}</td>
                          <td>{account.mask}</td>
                          <td>Available: {account.balances.available}, Current: {account.balances.current}</td>
                          <td>
                            <button className="btn btn-primary" onClick={() => handleViewDetails(account)}>
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accountreport;
