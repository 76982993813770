import React, { useCallback, useState } from 'react';
import Header from '../Componets/Header';
import SimplePlaidLink from '../Componets/SimplePlaidLink';
const DashboardPage = () => {
    const [instituteData, setInstituteData] = useState(null)
    const handelChangePublicToken = (metadata) => {
        setInstituteData(metadata)
    }
    return (
        <div>
            <Header />
            {instituteData === null ?
                <SimplePlaidLink handelChangePublicToken={handelChangePublicToken} /> :
                (<section className="banner-section index">
                        <div className="overlay">
                            <div className="banner-content">
                                <div className="container wow fadeInUp pt-5">
                                    <div className="main-content">
                                        <div className="top-area section-text">
                                            {/* <h5 className="sub-title">Trusted by over 3M customers</h5> */}
                                            <h1 className="title">Your  {" "}

                                                <span className="title" style={{
                                                    fontSize: "76px",
                                                    lineHeight: "98.8px",
                                                    color: '#00be90e8'
                                                }}>
                                                    {instituteData.institution.name}
                                                </span>  account has been successfully linked.</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
        </div>
    );
};

export default DashboardPage;