import React, { useState, useEffect } from "react";
import "./Admin.css";

import { Link, useNavigate } from "react-router-dom";
import Adminsidebar from "./Adminsidebar";
import AdminMobileSidebar from "./AdminMobileSidebar";
import { getApi, postApi } from "../../service/apiService";
import { ToastContainer, toast } from 'react-toastify';

function AdminUserList() {
  const [usersData, setUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const fetchUsersData = async () => {

    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const data = await getApi('users/allUsers', { headers });
      // console.log('Data loaded successfully', data);
      setUsersData(data)
    } catch (error) {
      console.error('Error occurred while fetching data', error);
      toast.error("Error occurred while fetching data");
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const deleteUser = async (user) => {
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const data = await postApi('users/delete', { userId: user._id }, headers );
      if (data.success) {
        toast.info("User Delete Successfully ");
        await fetchUsersData();
      } else {
        toast.error("Unable to delete User");
      }

    } catch (error) {
      console.error('Error occurred while fetching data', error);
      toast.error("Internal Server Error");

    }
  };




  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Filter users based on search term
  const filteredUsers = usersData.filter((user) =>
    `${user.first_name} ${user.middle_name} ${user.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <body>
        <Adminsidebar />
        <section class="home-section">
          <AdminMobileSidebar />
          <div class="home-content">
            <div class="sales-boxes">
              <div class="recent-sales box">
                <div class="title">All Users</div>
                {/* Search input field */}
                <div class="search-box">
                  <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <table
                  class="table table-striped"
                  style={{ marginTop: "25px" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">User Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone number</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUsers.map((user, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {user.first_name} {user.middle_name} {user.last_name}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>

                        <td>{formatDate(user.createdAt)}</td>
                        <td><button className=" btn btn-danger" onClick={() => deleteUser(user)}>Delete</button></td>
                        {/* You need to replace this with appropriate field */}
                        {/* You can render more user details here */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div class="button">
                  <Link
                    to="#"
                    onClick={() => paginate(currentPage - 1)}
                    className="mx-2"
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Link>
                  <Link
                    to="#"
                    onClick={() => paginate(currentPage + 1)}
                    className="mx-2"
                    disabled={indexOfLastUser >= filteredUsers.length}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </div>
  );
}

export default AdminUserList;
