import React from 'react';
import AdminMobileSidebar from './AdminMobileSidebar';
import Adminsidebar from './Adminsidebar';
import Register from '../../Pages/Register';
function AdminSetting() {
  return (
    <body>
      <Adminsidebar />
      <section className="home-section">
        <AdminMobileSidebar />
        <Register/>
      </section>
    </body>
  );
}

export default AdminSetting;
