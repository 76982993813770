import React, { useState, useEffect } from 'react';
import './Admin.css';
import { Link, useNavigate } from 'react-router-dom';
import Adminsidebar from './Adminsidebar';
import AdminMobileSidebar from './AdminMobileSidebar';
import { getApi } from '../../service/apiService';

function AccountUserDetails() {
    const [accountData, setAccountData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [loader , setLoader] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const fetchUsersAccounts = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const data = await getApi('plaid/get_all_reports', { headers });
            setAccountData(data.data);
        } catch (error) {
            console.error('Error occurred while fetching data', error);
        }
    };

    useEffect(() => {
     

        fetchUsersAccounts();
    }, []);



    const fetchSearchAccounts = async () => {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            let searchData = searchValue.trim()
            // Check if the search value is empty
            if (!searchValue  || searchData.length === 0) {
                // Fetch all reports
                const data = await getApi('plaid/get_all_reports', { headers });
                setAccountData(data.data);
            } else {
  
                const queryParams = encodeURIComponent(searchData);
                const apiUrl = `plaid/get_all_reports?term=${queryParams}`;
                const data = await getApi(apiUrl, { headers });
                setAccountData(data.data);
            }
            setCurrentPage(1)
        } catch (error) {
            console.error('Error occurred while fetching data', error);
        }
    };
    

    const totalPages = Math.ceil(accountData.length / 5);
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;
    const currentData = accountData.slice(startIndex, endIndex);

    const getReportByID = async (report) => {
        setLoader(true);
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const url = `plaid/get_report_by_id/${report.asset_report_id}`;
            const data = await getApi(url, { headers });
            sessionStorage.setItem("reportToken", data.data.assetReportToken);
            navigate('/report', { state: data.data });
            setLoader(false);
        } catch (error) {
            console.error('Error occurred while fetching data', error);
            setLoader(false)
        }
    };

    const nextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <>
            <body>
                <Adminsidebar />
                <section className="home-section">
                    <AdminMobileSidebar />
                    {
                        loader && 
                        <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    }
                    <div className="home-content">
                        <div className="overview-boxes">
                            <div className="box">
                                <div className="right-side">
                                    <div className="box-topic">Total Reports</div>
                                    <div className="number">{accountData.length}</div>
                                    <div className="indicator">
                                        <i className='bx bx-up-arrow-alt'></i>
                                        <span className="text">Up from yesterday</span>
                                    </div>
                                </div>
                                <i className='bx bxs-cart-add cart two'></i>
                            </div>
                        </div>
                        <div className="sales-boxes">
                            <div className="recent-sales box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="title">Total Reports</div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                                        <input placeholder='Search by Name or Email' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                        <button className='"btn btn-success mx-2' style={{ padding:'10px', border:'1px solid black' , borderRadius:'10px'}} onClick={fetchSearchAccounts}>Search</button>
                                    </div>
                                    
                                </div>

                                <table className="table table-striped" style={{ marginTop: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Days Requested</th>
                                            <th scope="col">Generated Date</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData.map((account, index) => (
                                            <tr key={index}>
                                                <th scope="row">{startIndex + index + 1}</th>
                                                <td>{account.user.first_name} {account.user.middle_name} {account.user.last_name}</td>
                                                <td>{account.user.email}</td>
                                                <td>{account.days_requested}</td>
                                                <td>{formatDate(account.date_generated)}</td>
                                                <td><button className=" btn btn-info" onClick={() => getReportByID(account)}>View Details</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="button">
                                    {currentPage > 1 && <button className="mx-2 btn btn-info" onClick={prevPage}>Previous</button>}
                                    {currentPage < totalPages && <button className="mx-2 btn btn-info" onClick={nextPage}>Next</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </body>
        </>
    );
}

export default AccountUserDetails;
